
                @import "src/styles/brands/southern/_settings";
              
.order-summary {
    h1 {
        font-size: 22px;
        text-align: center;
    }

    h2 {
        font-size: 18px;
    }

    p {
        margin-bottom: 30px;
    }

    .tick-icon {
        display: block;
        width: 50px;
        margin: 20px auto -20px auto;
    }

    .plan-a-journey-button {
        display: block;
        margin: 0 auto;
        width: 373px;
    }
}
