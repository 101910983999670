
                @import "src/styles/brands/southern/_settings";
              
.payment {
    &__subheading {
        font-size: 32px;
        margin-bottom: 20px;
    }

    &__terms {
        margin-bottom: 40px;

        .form-switch {
            margin-bottom: 10px;
        }
    }

    .payment-options {
        margin: 0;
        padding: 0;

        &__option {
            border: 1px solid #000;
            border-radius: 10px;
            box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.05);
            list-style-type: none;
            margin-bottom: 40px;
            padding: 20px;

            h3 {
                font-size: 24px;
            }
        }
    }
}

.paypal-cta-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        width: 150px;
    }
}
