
                @import "src/styles/brands/southern/_settings";
              
.user-info {
  &__footer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    button {
      width: 210px;
    }
  }

  .photo-description {
    margin-top: 45px;
    font-size: 14px;
    max-width: 265px;

    @media only screen and (max-width: 767px) {
      display: inline-block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    ul {
        list-style-type: none;
        padding: 0; 

        li {
            margin-bottom: 10px;
        }
    }

    img {
        margin-right: 15px;
    }
  }

  .railcard-preview {
    width: 313px;
    height: 452px;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    @media only screen and (max-width: 767px) {
      margin-left: auto;
      margin-right: auto;
    }

    &__photo-upload {
      display: flex;
    }

    .photo-upload {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;

      &:nth-child(2) {
        margin-left: 10px;
      }

      &__remove {
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
      }

      &__preview {
        width: 182px;
        height: 225px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.2);
        border: 1px dashed #ffffff;
        position: relative;
        overflow: hidden;

        input {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 30px 20px;
          text-align: center;

          &:hover {
            cursor: pointer;
          }
          h6 {
            font-size: 14px;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }

    &.two-card-holders {
      &:first-child {
        margin-right: 7px;
      }
      &__preview {
        width: 100px;
      }

      .photo-upload {
        &__preview {
          width: 135px;
          height: 172px;
        }

        &__description {
          padding-left: 5px;
          padding-right: 5px;
          padding-bottom: 0;
        }
      }
    }

    &__footer {
      margin-top: 30px;
      width: 100%;

      p {
        margin: 0;
        font-size: 13px;
      }

      h4 {
        font-size: 19px;
      }

      .valid {
        margin-top: 10px;
      }
    }
  }
}
