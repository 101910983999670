
                @import "src/styles/brands/southern/_settings";
              


@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 140px;

    &--centered {
        transform: translateY(-50%);
    }

    & > i {
        margin-bottom: 20px;
        font-size: 50px;
    }

    & > span {
        height: 60px;
        width: 60px;
        color: #000;
    }
}

.circle-spinner {
    &:after {
        display: block;
        position: absolute;
        content: '';
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;         
        height: 60px;
        width: 60px;
        z-index: 3;
        border-radius: 50%;
        border: 7px solid $brand-secondary;
        border-left: 7px solid $brand-primary;
        font-size: 4em;
        top: 40px;
        left: calc(50% - 30px);
    }

    &--small {
        position: relative;

        &:after {
            height: 20px;
            width: 20px;
            border: 2px solid #fff;
            border-left: 2px solid #ccc;
            left: -10px;
        }
    }
}

.loading-message {
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
}

.loading--standalone {
    position: relative;
    top: 0;
}

.loading--inline {
    position: relative;
    top: 0;

    i {
        font-size: 12px;
        display: inline-block;
    }

    p {
        display: inline-block;
        margin-left: 5px;
    }
}

.loading-backdrop {
    filter: blur(1px);
}

.loading--payment { top: 35%; }

.loading-line {
    top: 0;
    left: 0;
    z-index: 10000;
    position: absolute;
    background: #9ea9ab;
    width: 100%;
    height: 4px;
    overflow: hidden;

    .break {
        position: absolute;
        background: darken($brand-primary, 5%);
        width: 40px;
        height: 4px;
    }

    .dot1{ animation: loading-line 3s infinite;}
    .dot2{ animation: loading-line 3s 0.5s infinite; }
    .dot3{ animation: loading-line 3s 1s infinite; }
}

@keyframes loading-line {
    from { left: 0; }
    to { left: 100%; }
}

.left-col--loading { min-height: 220px; }

@media (max-height: 530px) {
    .loading { top: 50px; }
}

@media (max-height: 450px) {
    .loading { top: 20px; }
}


.loading-circle {
    &:before {
        position: absolute;
        content: ' ';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        border-radius: 5px;
    }

    &:after {
        display: block;
        position: absolute;
        content: '';
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
        top: 50%;
        left: 50%;
        height: 60px;
        width: 60px;
        margin-left: -30px;
        margin-top: -30px;
        z-index: 3;
        border-radius: 50%;
        border: 5px solid $brand-secondary;
        border-left: 5px solid $brand-primary;;
        font-size: 4em;
    }

    &--small {
        &:after {
            height: 16px;
            width: 16px;
            margin-left: -8px;
            margin-top: -8px;
            border-width: 3px;
        }
    }
}
