
                @import "src/styles/brands/southern/_settings";
              
.railcard-item-wrapper {
    a {
        color: inherit;
    }
    .railcard-item {
        width: 360px;
        border-radius: 5px;
        border: 1px solid #E5E5E5;
        overflow: hidden;

        &--preview {
            max-width: 265px;
        }

        &__more-info-button {
            &:focus {
                border: 1px solid #fff;
            }
        }
        
        header {
            padding: 11px 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            .heading {
                display: flex;
                align-items: center;
                margin-bottom: 0;
    
                svg {
                    margin-right: 10px;
                }
    
                span {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
            
        }

        footer {
            hr {
                background: #757575;
                transform: scaleX(1.05);
            }

            .footer-content {
                display: flex;
                justify-content: space-between;
            }
        }

        a {
            text-decoration: none;
        }
    
        &__body {
            padding: 16px;
            ul {
                padding: 0;
                padding-left: 16px;
                list-style: none;
                font-size: 14px;
                margin-top: 0;
                margin-bottom: 10px;
    
    
                li {
                    margin-bottom: 5px;
                    &::before {
                        content: "\2022";  
                        color: #848484;
                        font-weight: 900;
                        display: inline-block; 
                        width: 1em;
                        margin-left: -1em;
                      }
                }
            }
           
        }
    
        &__select-button {
            padding: 12px 17px;
            background: white;
            border: 1.5px solid #EBEBEB;
            border-radius: 8px; 
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            &:last-child {
                margin-top: 10px;
            }
        }

        &__remove-button {
            color: #5E6770;
            font-size: 14px;
            display: block;
            text-align: end;
            margin-top: 5px;
        }
    }
}

