
                @import "src/styles/brands/southern/_settings";
              
.custom-select {
    font-weight: 400;
    font-size: 14px !important;

    .form-select {
        font-size: 14px;
    }
}