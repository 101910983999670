
                @import "src/styles/brands/southern/_settings";
              
.basket-summary-container {
    margin-bottom: 40px;

    &__more-info-button {
        &:focus {
            border: 1px solid #000;
        }
    }
}

.basket-summary {
    border: 1px solid #000;
    border-radius: 10px;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.05);
    list-style-type: none;
    padding: 20px;

    &__heading {
        font-size: 18px;
        font-weight: normal;
    }

    &__subheading {
        font-size: 16px;;
    }

    .railcard-info-icon {
        float: right;
    }

    .railcard-validity {
        margin: 0 0 10px 0;
        padding: 0;

        &__item {
            list-style-type: none;

            &__label {
                display: inline-block;
                width: 100px;
            }
        }
    }

    .name-and-price {
        display: flex;
        justify-content: space-between;
    }

    &__clear-basket {
        display: flex;
        justify-content: flex-end;

        .btn-link {
            color: #5E6770;
        }
    }
}